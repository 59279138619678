document.addEventListener("DOMContentLoaded", () => {
  const stickyElement = document.querySelector(".sticky-top");

  if (!stickyElement) {
    return;
  }

  window.addEventListener("scroll", () => {
    let viewportWidth = window.innerWidth;
    let scrollThreshold = 0;

    if (viewportWidth >= 992) {
      scrollThreshold = 96;
    } else if (viewportWidth >= 768) {
      scrollThreshold = 88;
    } else {
      scrollThreshold = 80;
    }

    if (window.scrollY >= scrollThreshold) {
      stickyElement.classList.add("is-stuck");
    } else {
      stickyElement.classList.remove("is-stuck");
    }
  });
});
