import { Controller } from '@hotwired/stimulus';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

export default class extends Controller {
  static targets = ['sliderWrapper', 'btnPrev', 'btnNext'];

  connect() {
    const swiper = new Swiper(this.sliderWrapperTarget, {
      navigation: {
        prevEl: this.btnPrevTarget,
        nextEl: this.btnNextTarget,
      },
      loop: true,
      modules: [Navigation],
      autoHeight: true,
    });
  }
}
