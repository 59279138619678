import { apiClient } from './apiClient';

export class ImageUpload {
  constructor(imageUploadWrapper) {
    this.label = imageUploadWrapper.querySelector('.js-image-upload-label');
    this.labelText = imageUploadWrapper.querySelector('.js-image-upload-label-text');
    this.fileInput = imageUploadWrapper.querySelector('.js-image-upload-file');
    this.button = imageUploadWrapper.querySelector('.js-image-upload-cta');
    this.previewImage = imageUploadWrapper.querySelector('.js-image-upload-image');
    this.removeButton = imageUploadWrapper.querySelector('.js-image-upload-remove-button');

    this.initialLabelHTML = this.labelText.innerHTML;

    this.maxFileSizeInMegabytes = imageUploadWrapper.dataset.maxFileSize || 4;
    this.maxFileSizeInBytes = this.maxFileSizeInMegabytes * 1048576;

    this.setupEventListeners();
  }

  setupEventListeners() {
    if (this.button) {
      this.button.addEventListener('click', (e) => {
        e.preventDefault();
        this.label.click();
      });
    }

    this.fileInput.addEventListener('change', (e) => {
      const { files } = this.fileInput;
      if (!files || (files && !files[0])) {
        this.showError(`Something went wrong. Please try again.`);
        return;
      }

      if (files[0].size > this.maxFileSizeInBytes) {
        this.showError(
          `This image is too large. Maximum file size is ${this.maxFileSizeInMegabytes}MB.`
        );
        return;
      }

      this.labelText.classList.remove('text-danger');
      this.setMessage('Loading…');
      this.setImage();
      this.previewImage.addEventListener('load', () => {
        setTimeout(() => {
          this.hideMessage();
          this.previewImage.classList.remove('d-none');
          this.removeButton?.classList.remove('d-none');
        }, 500);
      });
    });

    this.removeButton?.addEventListener('click', (e) => {
      if (this.removeButton.dataset.confirm && !window.confirm(this.removeButton.dataset.confirm)) {
        return false;
      }

      this.fileInput.value = '';
      this.previewImage.classList.add('d-none');
      this.resetMessage();
      this.labelText.classList.remove('d-none');
      this.removeButton.classList.add('d-none');

      if (this.removeButton.dataset.signedId) {
        apiClient(`/remove-attachment/${this.removeButton.dataset.signedId}`, {
          method: 'DELETE',
        }).then((res) => {
          this.removeButton.setAttribute('data-signed-id', '');
        });
      }
    });
  }

  setImage() {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (this.previewImage.nodeName === 'DIV') {
        const newImgNode = document.createElement('img');
        newImgNode.src = e.target.result;
        newImgNode.className = 'tile tile-lg';
        this.previewImage.replaceWith(newImgNode);
      } else {
        this.previewImage.src = e.target.result;
      }
    };
    reader.readAsDataURL(this.fileInput.files[0]);
  }

  setMessage(message) {
    this.labelText.innerHTML = message;
  }

  hideMessage() {
    this.labelText.classList.add('d-none');
  }

  resetMessage() {
    this.setMessage(this.initialLabelHTML);
  }

  showError(message) {
    this.labelText.classList.add('text-danger');
    this.labelText.classList.remove('d-none');
    this.setMessage(message);
    this.previewImage.classList.add('d-none');
  }
}
