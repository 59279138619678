import $ from 'jquery';
import { isValid as isPostcodeValid } from 'postcode';
import { disableButton, isDebugMode } from './util';

export class FormValidator {
  static validateBasicFields = (wrapperEl) => {
    let basicFieldsValid = true;
    [...wrapperEl.querySelectorAll('[required]')].forEach((field) => {
      const validationWrapper = field.closest('[data-validation-wrapper]');
      const fieldValid = field.checkValidity();
      if (!fieldValid) {
        basicFieldsValid = false;
        if (validationWrapper) {
          validationWrapper.classList.toggle('was-validated', !fieldValid);
        }
      }
    });

    return basicFieldsValid;
  };

  static validateClientSideValidationFields = (wrapperEl, validators) => {
    $(wrapperEl).enableClientSideValidations();
    const clientSideValidationPassing = $(wrapperEl).isValid(validators);
    return clientSideValidationPassing;
  };

  static validateFormCheckPanels = (wrapperEl) => {
    const formCheckPanelsWrappers = wrapperEl.querySelectorAll(
      '[data-form-check-panels-wrapper][data-required]'
    );
    let allFormCheckPanelsSelected = true;
    if (formCheckPanelsWrappers.length) {
      [...formCheckPanelsWrappers].forEach((formCheckPanelWrapper) => {
        const minRequired = parseInt(formCheckPanelWrapper.dataset.minimumRequired || 1, 10);
        let selectedInputsCount = 0;
        const formCheckPanelInputs = formCheckPanelWrapper.querySelectorAll('input');
        [...formCheckPanelInputs].forEach((input) => {
          if (input.checked) {
            selectedInputsCount += 1;
          }
        });
        const isFormCheckPanelValid = selectedInputsCount >= minRequired;

        if (!isFormCheckPanelValid) {
          allFormCheckPanelsSelected = false;
          formCheckPanelWrapper.classList.add('is-invalid');
        }
      });
    }

    return allFormCheckPanelsSelected;
  };

  static validateAutocompleteFields = (wrapperEl) => {
    const autocompleteFieldWrappers = wrapperEl.querySelectorAll('[data-controller="autocomplete"]');
    let allAutocompleteFieldsValid = true;
    if (autocompleteFieldWrappers.length) {
      [...autocompleteFieldWrappers].forEach((autocompleteFieldWrapper) => {
        const possibleValuesAttr = autocompleteFieldWrapper.getAttribute(
          'data-autocomplete-possible-values-value'
        );
        const possibleValues = possibleValuesAttr ? JSON.parse(possibleValuesAttr) : null;
        if (!possibleValues) {
          return;
        }

        const autocompleteField = autocompleteFieldWrapper.querySelector(
          '[data-autocomplete-target="input"]'
        );
        const hiddenField = autocompleteFieldWrapper.querySelector(
          '[data-autocomplete-target="hidden"]'
        );
        const errorWrapper = autocompleteFieldWrapper.querySelector(
          '[data-autocomplete-target="error"]'
        );

        const isPossiblValue =
          possibleValues.includes(hiddenField.value) ||
          possibleValues.includes(parseInt(hiddenField.value, 10));
        if (autocompleteField.value && !isPossiblValue) {
          autocompleteFieldWrapper.classList.add('is-invalid');
          errorWrapper.innerText = 'Please choose only from the available options';
          allAutocompleteFieldsValid = false;
        }
      });
    }

    return allAutocompleteFieldsValid;
  };

  static validateTextEditorFields = (wrapperEl) => {
    const textEditorTextareas = wrapperEl.querySelectorAll('[data-suneditor-target="textarea"]');
    let allTextEditorFieldsValid = true;
    if (textEditorTextareas.length) {
      [...textEditorTextareas].forEach((textEditorTextarea) => {
        const fieldRequired = textEditorTextarea.classList.contains('required');
        const sunEditorInstance = window.textEditors && window.textEditors.get(textEditorTextarea);

        if (!fieldRequired || !sunEditorInstance) {
          return;
        }

        const characterCount = sunEditorInstance.getCharCount();
        const isValid = characterCount > 0;

        if (!isValid) {
          allTextEditorFieldsValid = false;
        }

        textEditorTextarea.classList.toggle('is-invalid', !isValid);
      });
    }

    return allTextEditorFieldsValid;
  };

  static validateOtherFields = (wrapperEl) => {
    let otherFieldsValid = true;
    [...wrapperEl.querySelectorAll('.js-postcode-input')].forEach((field) => {
      const postcodeIsValid = isPostcodeValid(field.value);
      if (!postcodeIsValid) {
        otherFieldsValid = false;
        field.classList.add('is-invalid');
      }
    });

    return otherFieldsValid;
  };

  static validate = (wrapperEl, validators = null) => {
    if (isDebugMode()) {
      return true;
    }

    const formValidator = new FormValidator();

    const basicFieldsValid = formValidator.constructor.validateBasicFields(wrapperEl);

    const closestClientSideValidationWrapper = wrapperEl.closest('[data-client-side-validations]');
    let clientSideValidationPassing = true;
    if (closestClientSideValidationWrapper) {
      clientSideValidationPassing = formValidator.constructor.validateClientSideValidationFields(
        closestClientSideValidationWrapper,
        validators
      );
    }
    const formCheckPanelsValid = formValidator.constructor.validateFormCheckPanels(wrapperEl);

    const autocompleteFieldsValid = formValidator.constructor.validateAutocompleteFields(wrapperEl);

    const textEditorFieldsValid = formValidator.constructor.validateTextEditorFields(wrapperEl);

    const otherFieldsValid = formValidator.constructor.validateOtherFields(wrapperEl);

    return (
      basicFieldsValid &&
      clientSideValidationPassing &&
      formCheckPanelsValid &&
      autocompleteFieldsValid &&
      textEditorFieldsValid &&
      otherFieldsValid
    );
  };

  static enableBootstrapValidation = (form, callback, submitDelay = 0) => {
    form.addEventListener(
      'submit',
      (event) => {
        event.preventDefault();
        event.stopPropagation();
        const bsValidationPassed = event.target.checkValidity();
        event.target.classList.add('was-validated');

        if (bsValidationPassed) {
          const submitBtn = form.querySelector('[data-form-submit-btn]');
          disableButton(submitBtn);

          if (callback) { callback(); }

          setTimeout(() => {
            form.submit();
          }, submitDelay);
        }
      },
      false
    );
  };
}

export const COMMONLY_MISSPELLED_EMAIL_DOMAINS = [
  'gmial.com',
  'gamil.com',
  'gmai.com',
  'gmal.com',
  'gmail.con',
  'gmail.ocm',
  'gmail.cm',
  'gmil.com',
  'gmailcom',
  'gogglemail.com',
  'googlmail.com',
  'gmaill.com',
  'gmall.com',
  'gmaul.com',
  'gmeil.com',
  'gnail.com',
  'gmail.co',
  'gmail.con.',
  'gmail.cm.',
  'gmail.ocm.',
  'gmil.com.',
  'gmaill.com.',
  'gmailcom.',
  'gogglemail.com.',
  'googlmail.com.',
  'gmall.com.',
  'gmaul.com.',
  'gmeil.com.',
  'gnail.com.',
  'mail.google.com.',
  'gmaii.com',
  'gmali.com',
  'gimail.com',
  'gmaik.com',
  'gmail.vom',
  'gmail.cpm',
  'gmail.xom',
  'g.mail.com',
  'gmil.co',
  'gmail.cmo',
  'gimail.cm',
  'gamil.cm',
  'gmaii.co',
  'gmai.come',
  'gmal.com.',
  'gmai.co',
  'gmaul.co',
  'gmeil.co',
  'gmil.co.',
  'gmail.con.uk',
  'gmailcom.uk',
  'gogglemail.co.uk',
  'gmail.cok',
  'gmail.co.',
  'gmail.co.u',
  'gmail.coi.uk',
  'gmail.colm',
  'gmailcom.co.uk',
  'gmail.xom.',
  'gmail.com.uk',
  'gmilcom',
  'gmail.cmo.uk',
  'gmail.oc.uk',
  'gogglemail.com.uk',
  'gimail.com.uk',
  'gmail.conm',
  'gmil.ocm',
  'gmail.coj',
  'gmil.cm',
  'gmai.com.',
  'gmail.co.uk.',
  'gogglemail.co.uk.',
  'gmail.con.uk.',
  'gmailcom.uk.',
  'gogglemail.co.',
  'gogglemail.com.uk.',
  'gmailcom.co.',
  'gmail.co.uk',
  'gmilcom.',
  'gimail.com.',
  'gmaul.co.uk',
  'gmail.couk',
  'gmail.coim',
  'gmailcom.co',
  'gmail.cm.uk',
  'gmail.co.jk',
  'gmai.co.uk',
  'gmial.co.uk',
  'gmal.co.uk',
  'gmial.com.uk',
  'gmial.com.',
  'gmaul.co.uk.',
  'gmeil.co.uk.',
  'gnail.co.uk',
  'gmaol.com',
  'gmaio.com',
  'gmeil.ocm',
  'gimail.cm.',
  'gimail.co.',
  'gimail.co.uk.',
  'gmai.co.',
  'gamil.co.',
  'hotmail.co.ukk',
  'hotmaill.co.uk',
  'hotmal.co.uk',
  'hotmial.co.uk',
  'hotmil.co.uk',
  'homail.co.uk',
  'hotail.co.uk',
  'hotmail.co.ik',
  'hotmai.co.uk.',
  'hotmail.co.uk.',
  'hotmai.co.uk',
  'hotmail.com.uk',
  'hotmai.com.uk',
  'hotmail.c.uk',
  'hotmial.co.uk.',
  'hotmal.co.uk.',
  'hotmaill.com.uk',
  'hotmial.com.uk',
  'homtail.co.uk',
  'hormail.co.uk',
  'hotmaik.co.uk',
  'hotmali.co.uk',
  'hotnail.co.uk',
  'htomail.co.uk',
  'hotamail.co.uk',
  'hotimal.co.uk',
  'hotmail.couk',
  'hotmal.com.uk',
  'hotmai.co.',
  'hotmail.u.k',
  'hotmail.co.ik.',
  'hotmal.com',
  'hotmai.com',
  'hotmial.com',
  'hotnail.com',
  'homail.com',
  'hotmail.cl.uk',
  'hotmial.com.',
  'hotmal.co',
  'hotmailco.uk',
  'hotmail.ck.uk',
  'hotmaul.co.uk',
  'htmail.co.uk',
  'hotmail.oc.uk',
  'homtail.com',
  'hotmiail.co.uk',
  'hotmaii.co.uk',
  'hotmaiil.co.uk',
  'hotmail.coj.uk',
  'hotmail.clm.uk',
  'hotmail.co.ku',
  'hotmai.ocm.uk',
  'hotmaiol.co.uk',
  'hotmaip.co.uk',
  'hotmaill.co',
  'hotmail.co0uk',
  'hotmail.cko.uk',
  'hotmaul.com',
  'hotimail.co.uk',
  'hotmial.c.uk',
  'hotmail.cl.uk.',
  'hotmaul.com.uk',
  'hotmail.xo.uk',
  'hotmail.comu.k',
  'hotmaik.com.uk',
  'hotmil.com.uk.',
  'hotmail.cm.uk',
  'hotmil.com.uk',
  'hotmaul.co',
  'hotmail.ck.uk.',
  'hotmaiol.com.uk',
  'hotmail.cp.uk',
  'hotmaim.co.uk',
  'hotmaio.co.uk',
  'hotmil.co',
  'hotmail.cmo.uk',
  'homail.co',
  'hotmaill.cm.uk',
  'hotmail.co.uk.uk',
  'hotmaillcom.uk',
  'hotmail.co.uk..',
  'hotmail.col.uk',
  'hotmail.c.o.uk',
  'hotmail.com.uk.',
  'hotmail.comm.uk',
  'hotmai.oc.uk.',
  'hotmaill.co.',
  'hotmil.co.uk.',
  'hotmail.u',
  'hotmail.coj.uk.',
  'hotmail.co.ouk',
  'hotmail.ckm.uk',
  'hotmail.o.uk',
  'hotmail.con.uk',
  'hotmail.c.uk.',
  'hotmaiol.com',
  'hotmail.con.uk.',
  'hotmaii.com.uk',
  'hotmailcom.uk.',
  'hotmail.com.u',
  'homtail.co.uk.',
  'hotmial.co',
  'hotmail.cm.',
  'yaho.co.uk',
  'yaho.com.uk',
  'yahoo.c.uk',
  'yaho.co',
  'yahoomail.co.uk',
  'yahomail.co.uk',
  'yaoo.co.uk',
  'yahoo.co.uk.',
  'yaho.uk',
  'yahhoo.co.uk',
  'yahoo.co.ukk',
  'yahoo.couk',
  'yahoo.com.uk.',
  'yaahoo.co.uk',
  'yahho.co.uk',
  'yahoomail.co',
  'yahoo.cl.uk',
  'yahoomail.com.uk',
  'yahoo.cok.uk',
  'yahoo.ck.uk',
  'yahoo.c',
  'yahoo.uk',
  'yaho.com',
  'yahoo.con.uk',
  'yaho.mai.co.uk',
  'yahoom.co.uk',
  'yahool.co.uk',
  'yahooc.co.uk',
  'yahoo.coj.uk',
  'yahooco.uk',
  'yahhoo.com.uk',
  'yahoo.om.uk',
  'yahoomail.co.',
  'yaho.con.uk',
  'yahoocl.uk',
  'yahoo.cu.uk',
  'yahoo.co.uk..',
  'yahou.co.uk',
  'yahoomail.c.uk',
  'yahoo.col.uk',
  'yahii.co.uk',
  'yahoom.co',
  'yahoo.co.ik',
  'yaho.ck.uk',
  'yaho.co.uk.',
  'yahoo.clm.uk',
  'yahoo.co.',
  'yahoouk',
  'yahooo.co.uk',
  'yahoo.couk.',
  'yahoo.con',
  'yahoo.conm.uk',
  'yahoo.cpm.uk',
  'yahoomail.co.u',
  'yahioo.co.uk',
  'yahoo.uk.',
  'yahoomailcom.uk',
  'yahooo.com.uk',
  'yahoom.co.uk.',
  'yahomail.com.uk',
  'yahou.com.uk',
  'yahoo.co.ukk.',
  'yahho.com.uk',
  'yahoo.cp.uk',
  'yahoomailc.uk',
  'yahoi.co.uk',
  'yaho.com.uk.',
  'yahooo.co',
  'yahoo.u.k',
  'yahoomail.coj.uk',
  'yahoo.oc.uk',
  'yahoo.comm.uk',
  'yahmoo.co.uk',
  'yahoo.con.uk.',
  'yaho.co.uk..',
  'yahooc.om.uk',
  'yahoo.om',
  'yahoo.comu.k',
  'yahoo.cmo.uk',
  'yahoo.cok',
  'yahooco.uk.',
  'yahoo.ocm.uk',
  'yahoomai.co.uk',
  'yahomail.co',
  'yaho.cm.uk',
  'yahoo.cok.',
  'yahoo.co.ukl',
  'yahoocom.uk',
  'yahoomai.com.uk',
  'yahoo.c.u.k',
  'yahoo.cm.uk.',
  'yahoo.col.uk.',
  'yahoo.cim.uk',
  'yahoo.com.ukk',
  'yahoomail.com',
  'yahooc.co.uk.',
  'yahopmail.co.uk',
  'yahoocl.com.uk',
  'yahooo.com',
  'yahoomail.co.u.',
  'yahoo.cu',
  'yahoo.co.u',
  'yaho.co.u',
  'yahoo.comuk',
  'yahoo.co0uk',
  'yahou.co',
  'outlookk.com',
  'outlook.co.ukk',
  'outloo.com',
  'outlook.cm',
  'outlok.com',
  'outlook.c.uk',
  'outlookcom',
  'oulook.com',
  'outlook.uk',
  'oulook.co.uk',
  'outloo.co.uk',
  'outloook.com',
  'outlook.couk',
  'outlook.cm.uk',
  'outlookcom.uk',
  'outlookco.uk',
  'outlok.co.uk',
  'outlook.om',
  'outlook.comu',
  'outllok.com',
  'outlookcomuk',
  'outlook.cim.uk',
  'outlook.con.uk',
  'outlook.com.ukk',
  'outlook.cou.uk',
  'outlok.co',
  'outlook.conm.uk',
  'outlokk.com',
  'oulook.co',
  'outloo.com.uk',
  'outlook.col.uk',
  'outlook.cok.uk',
  'outlook.c.u',
  'outlook.cp.uk',
  'outlook.cm.uk.',
  'outlook.con',
  'outlook.oc.uk',
  'outlook.co0uk',
  'outlokk.co.uk',
  'outlokk.com.uk',
  'outlookcom.uk.',
  'outlook.comm.uk',
  'outlook.cl.uk',
  'outlook.om.uk',
  'outlook.co.uk.',
  'outlook.con.uk.',
  'outlookc.uk',
  'outlook.cok',
  'outlookcom.co.uk',
  'outlook.col.uk.',
  'outlookco.uk.',
  'outlook.co.u.',
  'outlool.com',
  'outlok.com.uk.',
  'outlook.cu.uk',
  'outloo.co',
  'outlook.conm',
  'outlook.cok.',
  'outlook.co0.uk',
  'outlook.co.ukk.',
  'outlook.com.uk.',
  'outlook.comm',
  'outlookcomco.uk',
  'outlookc.co.uk',
  'outlookc.om.uk',
  'outlookc.uk.',
  'outlookc.co',
  'outlookck.com',
  'outlookco.com',
  'outlookcon.uk',
  'outlookcp.uk',
  'outlookk.co.uk',
  'outlookk.com.uk',
  'outlookl.co.uk',
  'outlookl.com',
  'outlookm.co.uk',
  'outlooko.co.uk',
  'outlooko.com',
  'outlooku.co.uk',
  'outloooc.co.uk',
  'outloook.co.uk',
  'outlok.co.',
  'outloock.com',
  'outlool.co.uk',
  'outloook.co',
  'outloook.co.uk.',
  'outlouk.com',
  'outlouk.co.uk',
  'outloo.co.uk.',
  'outloo.con.uk',
  'outloo.comuk',
  'outlook.c',
  'outlook.c.uk.',
  'outlook.cim',
  'outlook.clm.uk',
  'outlook.clom.uk',
  'outlook.cm.u',
  'outlook.cm.ukk',
  'outlook.co',
  'outlook.co.',
  'yaoo.com',
  'yhaoo.com',
  'yahho.com',
  'yahop.com',
  'yajoo.com',
  'yagoo.com',
  'ahoo.com',
  'gmail.co.ukk',
  'gmsil.com',
  'gmail.c.uk',
  'hotmsil.com',
  'hotmaol.com',
  'hotmsil.co.uk',
  'hotmil.com',
  'hotamil.com',
  'hotamail.com',
  'hotmaill.com',
  'hotimail.com',
  'hotail.com',
  'htmail.com',
  'hotmai.con',
  'hotmial.con',
  'hotmaail.com',
  'hotmsail.com',
  'hoymail.co.uk',
  'yagoo.co.uk',
  'yaho.co.ukk',
  'yahool.com',
  'yahhoo.com',
  'yaahoo.com',
  'yhaoo.co.uk',
  'yahoo.com.uk',
  'yhaoo.com.uk',
  'yaoo.com.uk',
  'gmsil.co.uk',
  'gmail.comu',
  'gamil.co.uk',
  'gmaill.co.uk',
  'hotmail.comu',
  'hotmail.cm',
  'hotmaik.com',
  'hotmail.conm',
  'hotmil.con',
  'hotmail.con',
  'hotmsil.com.uk',
  'htomail.com',
  'hoymail.com',
  'yahoo.co',
  'yahoo.comm',
  'yahoo.com.',
  'yahoo.comu',
  'yahhoo.co',
  'yaahoo.co',
  'yahoocouk',
  'yahood.com',
  'yhaoo.comm.uk',
  'yahood.co.uk',
  'yahho.co.uk.',
  'yajoo.co.uk',
  'hotmail',
  'gmail',
  'outlook',
  'yahoo',
];
