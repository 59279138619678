import { Controller } from '@hotwired/stimulus';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

export default class extends Controller {
  static targets = ['sliderWrapper', 'btnPrev', 'btnNext'];

  connect() {
    const swiper = new Swiper(this.sliderWrapperTarget, {
      breakpoints: {
        768: {
          slidesPerView: 2.175,
          slidesPerGroup: 2,
          spaceBetween: 16,
        },
        992: {
          slidesPerView: 3.175,
          slidesPerGroup: 2,
          spaceBetween: 20,
        },
        1200: {
          slidesPerView: 4.175,
          slidesPerGroup: 4,
          spaceBetween: 24,
        },
        1640: {
          slidesPerView: 4.675,
          slidesPerGroup: 4,
          spaceBetween: 24,
        },
        1880: {
          slidesPerView: 5.275,
          slidesPerGroup: 5,
          spaceBetween: 24,
        },
      },
      modules: [Navigation],
      navigation: {
        prevEl: this.btnPrevTarget,
        nextEl: this.btnNextTarget,
      },
      slidesPerView: 1.175,
      slidesPerGroup: 1,
      spaceBetween: 12,
      watchSlidesProgress: true,
    });
  }
}
