import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [];

  connect() {
    this.endDateTimestamp = this.element.dataset.endDateTimestamp;

    if (!this.isInThePast(this.endDateTimestamp)) {
      this.timeLeft = this.calculateTimeLeft();
      this.updateCountdown();

      const countdownInterval = setInterval(() => {
        this.timeLeft = this.calculateTimeLeft();
        const { hours, minutes, seconds } = this.timeLeft;
        if (hours === 0 && minutes === 0 && seconds === 0) {
          clearInterval(countdownInterval);
        }

        this.updateCountdown();
      }, 1000);
    }
  }

  calculateTimeLeft() {
    const currentTimestamp = new Date().getTime();
    let timestampsDifference = this.endDateTimestamp - currentTimestamp;

    const hoursDifference = Math.floor(timestampsDifference / 1000 / 60 / 60);
    timestampsDifference -= hoursDifference * 1000 * 60 * 60;
    const minutesDifference = Math.floor(timestampsDifference / 1000 / 60);
    timestampsDifference -= minutesDifference * 1000 * 60;
    const secondsDifference = Math.floor(timestampsDifference / 1000);

    return {
      hours: hoursDifference,
      minutes: minutesDifference,
      seconds: secondsDifference,
    };
  }

  updateCountdown() {
    const { hours, minutes, seconds } = this.timeLeft;
    const hoursText = hours < 10 ? `0${hours}` : hours;
    const minutesText = minutes < 10 ? `0${minutes}` : minutes;
    const secondsText = seconds < 10 ? `0${seconds}` : seconds;
    this.element.innerText = `${hoursText}:${minutesText}:${secondsText}`;
  }

  isInThePast(timestamp) {
    return Date.now() >= timestamp;
  }
}
