import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';
import { capitalize, convertReg, convertToCurrency, sortObjectByKey } from '../../shared/util';
import { apiClient } from '../../shared/apiClient';

export default class extends Controller {
  static targets = [
    'form',
    'vrmInput',
    'mileageInput',
    'submit',
    'loader',
    'error',
    'inspectionContainer',
    'automotiveWarningsNotice',
    'automotiveWarningsCount',
    'experianHistory',
    'experianHistoryStatus',
    'autoTraderHistory',
    'autoTraderHistoryStatus',
    'autoTraderHistoryBadges',
    'autoTraderHistoryCheckLevel',
    'autoTraderFeaturesTable',
    'overallHistoryCheckStatus',
    'output',
    'vrmOutput',
    'makeLogoOutput',
    'makeModelOutput',
    'descriptionOutput',
    'retryButton',
    'existingVehicleLookup',
    'autocomplete',
    'lookupForm',
    'vehicleInfoIdOutput',
  ];

  initialize() {
    const urlParams = new URLSearchParams(window.location.search);
    this.vrm = urlParams.get('vrm');
    this.mileage = urlParams.get('mileage');

    const vehicleLookups = document.querySelectorAll(`[data-controller*=${this.identifier}]`);
    this.isInitialLookup = vehicleLookups.length === 1;

    if (this.vrm && this.mileage && this.isInitialLookup) {
      // for initial lookup (on page load) show loader if VRM & mileage are present
      this.loaderTarget.classList.remove('d-none');
      if (this.hasFormTarget) {
        this.formTarget.classList.add('d-none');
      }
    }
  }

  async connect() {
    // only run lookup onload for first controller (if VRM & mileage are present) as new lookups will be done manually
    if (this.isInitialLookup && this.vrm && this.mileage) {
      if (this.hasVrmInputTarget && this.hasMileageInputTarget) {
        this.vrmInputTarget.value = this.vrm;
        this.mileageInputTarget.value = this.mileage;
      }

      if (this.hasExistingVehicleLookupTarget) {
        this.existingVehicleLookupTarget.classList.add('d-none');
      }
      try {
        const lookupResult = await this.getVehicleData(this.vrm, this.mileage);
        this.loaderTarget.classList.add('d-none');
        this.outputTarget.classList.remove('d-none');
        if (this.hasFormTarget) {
          $(this.formTarget).slideUp();
        }
        this.displayOutput(lookupResult);
        this.outputTarget.classList.remove('d-none');
      } catch (error) {
        this.handleError(error);
      } finally {
        // this.formTarget.classList.add('d-none');
      }
    }

    if (this.hasAutocompleteTarget) {
      this.autocompleteTarget.addEventListener('autocomplete.change', (e) => {
        const { vehicle, vehicleInfo, vrm } = e.detail.selected.dataset;
        const vehicleData = JSON.parse(vehicle);
        const vehicleInfoData = JSON.parse(vehicleInfo);
        this.emitResultRetrievedEvent({ vehicle: vehicleData, vehicle_info: vehicleInfoData });
        this.displayOutput({ vehicle: vehicleData, vehicle_info: vehicleInfoData, vrm });
        this.outputTarget.classList.remove('d-none');
        this.lookupFormTarget.classList.add('d-none');
        this.existingVehicleLookupTarget.classList.add('d-none');
      });
    }
  }

  getVehicleData(vrm, mileage) {
    return new Promise((resolve, reject) => {
      apiClient(`/api/vehicle/lookup?vrm=${vrm}&mileage=${mileage}`)
        .then((response) => {
          if (this.element.dataset.successRedirectUrl) {
            const customEvent = new CustomEvent('er:showFullScreenLoader');
            document.dispatchEvent(customEvent);

            setTimeout(() => {
              const newUrlTemplate = this.element.dataset.successRedirectUrl;
              const newUrl = newUrlTemplate.replace('JS-SLUG-TO-REPLACE', response.valuation.slug);
              window.location.href = `${newUrl}?show_loading=true`;
            }, 200);

            return;
          }

          setTimeout(() => {
            this.emitResultRetrievedEvent(response);
            this.outputTarget.classList.remove('d-none');
            if (this.hasLookupFormTarget) {
              this.lookupFormTarget.classList.add('d-none');
            }
            if (this.hasFormTarget) {
              const formSubmitBtn = this.formTarget.querySelector('button');
              formSubmitBtn.disabled = true; // ! This is to prevent new form submission after line item form is filled out. Not sure why it even tries to submit it again, but this is a quick fix for now.
            }
            resolve(response);
          }, 200);
        })
        .catch((errorResponse) => {
          const { errors } = errorResponse;
          reject(errors);
        });
    });
  }

  emitResultRetrievedEvent(response) {
    const { vehicle, vehicle_info: vehicleInfo, valuation } = response;
    const customEvent = new CustomEvent('vehicle-lookup:resultRetrieved', {
      detail: { vehicle, vehicleInfo, valuation },
      bubbles: true,
    });
    this.element.dispatchEvent(customEvent);
  }

  validateForm() {
    let isValid = true;
    if (this.formTarget.checkValidity) {
      isValid = this.formTarget.checkValidity();
    } else {
      const vrmValid = this.vrmInputTarget.checkValidity();
      const mileageValid = this.mileageInputTarget.checkValidity();

      if (!vrmValid || !mileageValid) {
        isValid = false;
      }
    }
    this.formTarget.classList.add('was-validated');
    return isValid;
  }

  handleKeypress(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.handleSubmit();
    }
  }

  async handleSubmit(e) {
    if (e) {
      e.preventDefault();
    }

    if (!this.validateForm()) {
      return;
    }

    this.loaderTarget.classList.remove('d-none');
    this.formTarget.classList.add('d-none');
    if (this.hasExistingVehicleLookupTarget) {
      this.existingVehicleLookupTarget.classList.add('d-none');
    }

    try {
      const vehicleData = await this.getVehicleData(
        this.vrmInputTarget.value,
        this.mileageInputTarget.value
      );
      this.loaderTarget.classList.add('d-none');
      this.displayOutput(vehicleData);
      this.outputTarget.classList.remove('d-none');

      // update URL to contain VRM and mileage
      const searchParams = new URLSearchParams(window.location.search);
      const shouldUpdateVehicleParams =
        !searchParams.has('vrm') && !searchParams.has('mileage') && !searchParams.has('vehicle_info');
      if (shouldUpdateVehicleParams) {
        searchParams.set('vrm', this.vrmInputTarget.value.toUpperCase());
        searchParams.set('mileage', this.mileageInputTarget.value);
      }

      const newRelativePathQuery = `${window.location.pathname}?${searchParams.toString()}`;
      window.history.pushState(null, '', newRelativePathQuery);
    } catch (error) {
      this.handleError(error);
    } finally {
      this.formTarget.classList.add('d-none');
    }
  }

  handleError(errors) {
    const customEvent = new CustomEvent('vehicle-lookup:error', {
      bubbles: true,
    });
    document.dispatchEvent(customEvent);

    if (typeof errors === 'string') {
      this.errorTarget.querySelector('[data-message]').innerText = errors;
    } else {
      this.errorTarget.querySelector('[data-message]').innerText =
        errors && errors[0] ? errors[0] : 'Something went wrong. Please try again.';
    }

    this.loaderTarget.classList.add('d-none');
    this.errorTarget.classList.remove('d-none');
    this.formTarget.classList.add('d-none');
    this.retryButtonTarget.classList.remove('d-none');
  }

  generateVehicleInfoText(data) {
    const { vehicle, vehicle_info: vehicleInfo } = data;
    const {
      color,
      engine_capacity: engineCapacity,
      fuel,
      year_of_manufacture: yearOfManufacture,
      transmission,
    } = vehicle;

    let vehicleInfoText = '';

    if (vehicleInfo.mileage) {
      vehicleInfoText += `<li class='list-inline-item list-item-inline-bullet'>${parseFloat(
        vehicleInfo.mileage
      ).toLocaleString()} miles</li>`;
    }
    if (yearOfManufacture) {
      // year sometimes comes back as date, e.g: 2016-01-01
      if (yearOfManufacture.includes('-')) {
        const [year] = yearOfManufacture.split('-');
        vehicleInfoText += `<li class='list-inline-item list-item-inline-bullet'>${year}</li>`;
      } else {
        vehicleInfoText += `<li class='list-inline-item list-item-inline-bullet'>${yearOfManufacture}</li>`;
      }
    }
    if (color) {
      vehicleInfoText += `<li class='list-inline-item list-item-inline-bullet'>${color}</li>`;
    }

    const engineInfo = [];
    const isElectric = fuel && fuel === 'Electric';

    if (!isElectric) {
      const vehicleEngineCapacity = `${(parseFloat(engineCapacity) / 1000).toFixed(1)}`;
      engineInfo.push(`${vehicleEngineCapacity}-litre`);
    }

    if (fuel) {
      engineInfo.push(isElectric ? 'Electric' : fuel.toLowerCase());
    }

    if (engineInfo.length) {
      vehicleInfoText += `<li class='list-inline-item list-item-inline-bullet'>${engineInfo.join(
        ' '
      )}</li>`;
    }

    if (transmission) {
      vehicleInfoText += `<li class='list-inline-item list-item-inline-bullet'>${transmission}</li>`;
    }

    return vehicleInfoText;
  }

  generateAutoTraderHistory(vehicleCheck) {
    if (!vehicleCheck) {
      return '';
    }

    let warningsHTML = '';

    Object.entries(sortObjectByKey(vehicleCheck)).forEach(([k, v], i) => {
      if (
        k === 'v5cs' ||
        k === 'keeper_changes' ||
        k === 'previous_owners' ||
        k === 'year_of_manufacture' ||
        v === null
      ) {
        return;
      }

      const itemIconClass = `list-item-icon-${this.vehicleHistoryCheckIcon(k, v)}`;
      const checkLabel = this.generateVehicleHistoryCheckLabel(k, v);
      warningsHTML += `<li class="list-item list-item-icon-end ${itemIconClass} d-flex justify-content-between align-items-center">${checkLabel}</li>`;
    });

    return warningsHTML;
  }

  generateVehicleHistoryCheckLabel(key, value) {
    let label = '';

    switch (key) {
      case 'exported':
        label = value ? 'Exported' : 'Not exported';
        break;
      case 'imported':
        label = value ? 'Imported' : 'Not imported';
        break;
      case 'scrapped':
        label = value ? 'Recorded as scrapped' : 'Not recorded as scrapped';
        break;
      case 'stolen':
        label = value ? 'Recorded as stolen' : 'Not recorded as stolen';
        break;
      case 'high_risk':
        label = value ? '' : 'No third-party interest';
        break;
      case 'trade_finance':
        label = value ? '' : 'No outstanding stocking finance';
        break;
      case 'colour_changed':
        label = value ? 'Recorded colour change' : 'No recorded colour changes';
        break;
      case 'private_finance':
        label = value ? 'Outstanding finance' : 'No outstanding finance';
        break;
      case 'mileage_discrepancy':
        label = value ? 'Mileage discrepancy' : 'No mileage discrepancy';
        break;
      case 'registration_changed':
        label = value ? 'Recorded plate change' : 'No recorded plate changes';
        break;
      case 'insurance_writeoff_category':
        label = value ? `Recorded as Category ${value}` : 'Not recorded as a write-off';
        break;
      default:
        break;
    }

    return label;
  }

  vehicleHistoryCheckIcon(key, value) {
    let iconClass = '';
    let failIconClass = '';
    switch (key) {
      case 'exported':
      case 'imported':
      case 'high_risk':
      case 'mileage_discrepancy':
      case 'insurance_writeoff_category':
        failIconClass = 'warning-circle';
        break;
      case 'scrapped':
      case 'stolen':
        failIconClass = 'crossed-circle';
        break;
      case 'trade_finance':
      case 'colour_changed':
      case 'private_finance':
      case 'registration_changed':
        failIconClass = 'info-circle';
        break;
      default:
        break;
    }

    if (value === true || key === 'insurance_writeoff_category') {
      iconClass = failIconClass;
    } else if (value === false) {
      iconClass = 'checked-circle';
    } else {
      iconClass = 'unknown-circle';
    }

    return iconClass;
  }

  vehicleHistoryCheckStatus(vehicleCheck, provider) {
    let colorClass = 'text-body-secondary';
    let status = 'Unavailable';

    if (!vehicleCheck) {
      return `<span class='fw-semibold ${colorClass}'>${status}</span>`;
    }

    const vehicleCheckArray = Object.entries(vehicleCheck);
    const checksCount = vehicleCheckArray.length;
    const checksPassedCount = vehicleCheckArray.filter(
      ([k, v]) => v === false || (k === 'insurance_writeoff_category' && !v)
    ).length;
    const checksFailedCount = vehicleCheckArray.filter(([k, v]) => {
      return v === true || (k === 'insurance_writeoff_category' && v);
    }).length;

    if (checksCount === 0 || (checksCount > 0 && checksPassedCount === 0 && checksFailedCount === 0)) {
      // vehicleCheck is present but none of the properties provide any info
      return `<span class='fw-semibold ${colorClass}'>${status}</span>`;
    }

    if (provider) {
      colorClass =
        checksCount === checksPassedCount || checksFailedCount === 0 ? 'text-success' : 'text-warning';
      if (checksCount === checksPassedCount) {
        const checksPassedText = checksPassedCount > 1 ? 'checks' : 'check';
        status = `${checksPassedCount} ${checksPassedText} passed`;
      } else if (checksFailedCount === 0 && checksPassedCount > 0) {
        const checksPassedText = checksPassedCount > 1 ? 'checks' : 'check';
        status = `${checksPassedCount} ${checksPassedText} passed`;
      } else {
        const checksFailedText = checksFailedCount > 1 ? 'warnings' : 'warning';
        status = `${checksFailedCount} ${checksFailedText}`;
      }
    } else if (checksFailedCount === 0) {
      colorClass = 'text-success';
      status = 'All checks passed';
    } else if (checksFailedCount > 1) {
      colorClass = 'text-warning';
      status = 'Multiple warnings';
    } else {
      switch (true) {
        case vehicleCheck.exported:
        case vehicleCheck.imported:
        case vehicleCheck.high_risk:
        case vehicleCheck.mileage_discrepancy:
        case vehicleCheck.insurance_writeoff_category:
          colorClass = 'text-warning';
          break;
        case vehicleCheck.scrapped:
        case vehicleCheck.stolen:
          colorClass = 'text-danger';
          break;
        case vehicleCheck.trade_finance:
        case vehicleCheck.colour_changed:
        case vehicleCheck.private_finance:
        case vehicleCheck.registration_changed:
          colorClass = 'text-primary';
          break;
        default:
          break;
      }

      let skip = false;
      vehicleCheckArray.forEach(([k, v]) => {
        if (skip) {
          return;
        }

        if (v === true || (k === 'insurance_writeoff_category' && v)) {
          status = this.generateVehicleHistoryCheckLabel(k, v);
          skip = true;
        }
      });
    }

    return `<span class='fw-semibold ${colorClass}'>${status}</span>`;
  }

  generateAutoTraderHistoryStatus(autoTraderHistory) {
    if (!autoTraderHistory) {
      const colorClass = 'text-body-secondary';
      const status = 'Unavailable';

      return `<span class="fw-semibold ${colorClass}">${status}</span>`;
    }

    const { exported, imported, scrapped, stolen } = autoTraderHistory;
    const autoTraderHistoryChecks = [exported, imported, scrapped, stolen];

    let checksPassedCount = 0;
    autoTraderHistoryChecks.forEach((checkBool) => {
      if (!checkBool) {
        checksPassedCount += 1;
      }
    });

    const status = `${checksPassedCount} checks passed`;
    const colorClass =
      checksPassedCount === autoTraderHistoryChecks.length ? 'text-success' : 'text-warning';

    return `<span class="fw-semibold ${colorClass}">${status}</span>`;
  }

  generateExperianHistoryStatus(experianHistory) {
    if (!experianHistory) {
      // check unavailable
      const colorClass = 'text-body-secondary';
      const status = 'Unavailable';

      return `<span class="fw-semibold ${colorClass}">${status}</span>`;
    }

    const {
      automotive_exported: exported,
      automotive_imported: imported,
      automotive_scrapped: scrapped,
    } = experianHistory;
    const experianHistoryChecks = [exported, imported, scrapped];

    let checksPassedCount = 0;
    experianHistoryChecks.forEach((checkBool) => {
      if (checkBool === false) {
        checksPassedCount += 1;
      }
    });

    const allChecksUnavailable = experianHistoryChecks.every((checkBool) => checkBool === null);
    const anyCheckFailed = experianHistoryChecks.some((checkBool) => checkBool === false);
    let status = '';
    let colorClass = '';
    if (allChecksUnavailable) {
      colorClass = 'text-body-secondary';
      status = `Unavailable`;
    } else {
      const checksPassedText = checksPassedCount > 1 ? 'checks' : 'check';
      status = `${checksPassedCount} ${checksPassedText} passed`;
      colorClass = checksPassedCount === anyCheckFailed ? 'text-warning' : 'text-success';
    }

    return `<span class="fw-semibold ${colorClass}">${status}</span>`;
  }

  generateExperianHistory(experianHistory) {
    const {
      automotive_exported: exported,
      automotive_imported: imported,
      automotive_scrapped: scrapped,
    } = experianHistory;
    const experianHistoryChecksNames = ['Not exported', 'Not imported', 'Not recorded as scrapped'];
    const experianHistoryChecks = [exported, imported, scrapped];

    const allChecksUnavailable = experianHistoryChecks.every((checkBool) => checkBool === null);
    if (allChecksUnavailable) {
      return '';
    }

    let checkListItemsHTML = '';
    experianHistoryChecks.forEach((checkBool, i) => {
      const checkName = experianHistoryChecksNames[i];
      let iconClass = '';

      if (checkBool === null) {
        iconClass = 'unknown';
      } else if (checkBool === false) {
        iconClass = 'checked';
      } else {
        iconClass = 'crossed';
      }
      const listItemHTML = `<li class="list-item list-item-icon list-item-icon-end list-item-icon-${iconClass}-circle">${checkName}</li>`;
      checkListItemsHTML += listItemHTML;
    });

    return checkListItemsHTML;
  }

  generateAutoTraderCheckBadges(vehicleCheck) {
    let badgesHTML = '';
    Object.entries(vehicleCheck).forEach(([k, v], i) => {
      if (k === 'v5cs' || k === 'keeperChanges' || k === 'previousOwners') {
        return;
      }
      // const isOk = k === 'insuranceWriteoffCategory' ? v == null : !v;
      // if (!isOk) {
      //   if (k === 'insuranceWriteoffCategory') {
      //     badgesHTML += `<span class="badge badge-warning badge-xs ms-1">Cat ${v}</span>`;
      //   } else {
      //     badgesHTML += `<span class="badge badge-warning badge-xs ms-1">${capitalize(k)}</span>`;
      //   }
      // }
      if (k !== 'insuranceWriteoffCategory' && v === true) {
        badgesHTML += `<span class="badge badge-warning badge-xs ms-1">${capitalize(k)}</span>`;
      }
    });

    return badgesHTML;
  }

  autoTraderHistoryCheckLevelLabel(autoTraderHistory) {
    const fullVehicleCheck = autoTraderHistory && Object.keys(autoTraderHistory).length > 5;
    return fullVehicleCheck ? 'Full' : 'Basic';
  }

  displayOutput(data) {
    const { vehicle, vehicle_info: vehicleInfo, vrm } = data;
    const { make, make_model: makeModel, auto_trader_features: autoTraderFeatures } = vehicle;
    const {
      auto_trader_history: autoTraderHistory,
      auto_trader_check: autoTraderCheck,
      automotive_warnings: automotiveWarnings,
      automotive_exported: automotiveExported,
      automotive_imported: automotiveImported,
      automotive_scrapped: automotiveScrapped,
    } = vehicleInfo;
    const experianHistoryCheck = {
      exported: automotiveExported,
      imported: automotiveImported,
      scrapped: automotiveScrapped,
    };
    const autoTraderHistoryCheck = autoTraderCheck || autoTraderHistory; // Try to use fullVehicleCheck data but fallback to history
    const autoTraderHistoryCheckLevel = this.autoTraderHistoryCheckLevelLabel(autoTraderHistoryCheck); // "Basic" or "Full"

    this.vrmOutputTarget.innerHTML = convertReg(vrm);
    if (this.hasMakeLogoOutputTarget) {
      this.makeLogoOutputTarget.setAttribute('src', window.getMakeLogo(make));
    }

    if (this.hasVehicleInfoIdOutputTarget) {
      this.vehicleInfoIdOutputTarget.value = vehicleInfo.id;
    }

    if (this.hasMakeModelOutputTarget) {
      this.makeModelOutputTarget.innerHTML = `${makeModel}`;
    }

    if (this.hasDescriptionOutputTarget) {
      this.descriptionOutputTarget.innerHTML = this.generateVehicleInfoText(data);
    }

    if (this.hasInspectionContainerTarget) {
      this.inspectionContainerTarget.classList.remove('d-none');

      if (this.hasExperianHistoryStatusTarget) {
        this.experianHistoryStatusTarget.innerHTML = this.generateExperianHistoryStatus(vehicleInfo);
      }
      if (this.hasExperianHistoryTarget) {
        this.experianHistoryTarget.innerHTML = this.generateExperianHistory(vehicleInfo);
      }

      if (this.hasAutoTraderHistoryCheckLevelTarget) {
        this.autoTraderHistoryCheckLevelTargets.forEach((checkLevelLabel) => {
          checkLevelLabel.innerText = autoTraderHistoryCheckLevel;
        });
      }

      if (this.hasOverallHistoryCheckStatusTarget) {
        // Combined history status for Automotive (Experian) and AutoTrader
        let vehicleHistoryCheck = experianHistoryCheck || {};
        if (autoTraderHistoryCheck) {
          vehicleHistoryCheck = this.mergeVehicleChecks(experianHistoryCheck, autoTraderHistoryCheck);
        }

        this.overallHistoryCheckStatusTarget.innerHTML =
          this.vehicleHistoryCheckStatus(vehicleHistoryCheck);
      }

      if (this.hasExperianHistoryStatusTarget) {
        // Automotive (Experian) status
        this.experianHistoryStatusTarget.innerHTML = this.vehicleHistoryCheckStatus(
          experianHistoryCheck,
          'experian'
        );
      }

      if (this.hasAutoTraderHistoryTarget) {
        this.autoTraderHistoryTarget.innerHTML = this.generateAutoTraderHistory(autoTraderHistoryCheck);
      }

      if (this.hasAutoTraderHistoryStatusTarget) {
        this.autoTraderHistoryStatusTarget.innerHTML =
          this.vehicleHistoryCheckStatus(autoTraderHistoryCheck);
      }
    }

    if (this.hasAutomotiveWarningsCountTarget && this.hasAutomotiveWarningsNoticeTarget) {
      this.automotiveWarningsCountTarget.innerHTML = automotiveWarnings?.length || 0;
      this.automotiveWarningsNoticeTarget.classList.add('d-none');
    }

    if (autoTraderHistory && this.hasAutoTraderHistoryBadgesTarget) {
      this.autoTraderHistoryBadgesTarget.innerHTML =
        this.generateAutoTraderCheckBadges(autoTraderHistory);
    }

    if (this.hasAutoTraderFeaturesTableTarget) {
      const tbody = this.autoTraderFeaturesTableTarget.querySelector('tbody');

      if (autoTraderFeatures && autoTraderFeatures.length) {
        autoTraderFeatures.forEach(({ name, type, basic_price, vat_price }) => {
          const fullPrice = basic_price && vat_price ? convertToCurrency(basic_price + vat_price) : '–';
          const tr = `
            <tr>
              <th scope="col">${name}</th>
              <th scope="col">${type}</th>
              <th scope="col">${fullPrice}</th>
              <th><input type="checkbox" name="stock_item[listing_attributes][vehicle_info_attributes][features][]" class="form-check-input" value="${name}"></th>
            </tr>
          `;

          tbody.innerHTML += tr;
        });
      } else {
        const specificationWrapper = tbody.closest('[data-specification-wrapper]');
        specificationWrapper.innerHTML =
          '<p class="mb-0">There’s no specification available for this vehicle.</p>';
      }
    }

    // if (this.hasFormTarget) {
    //   $(this.formTarget).slideUp(() => {
    //     $(this.outputTarget).slideDown();
    //     this.dispatch('displayOutput');
    //   });
    // }
  }

  mergeVehicleChecks(experianHistoryCheck, autoTraderHistoryCheck) {
    // Use autoTraderHistoryCheck check as base
    const result = { ...autoTraderHistoryCheck };

    for (const key in autoTraderHistoryCheck) {
      if (experianHistoryCheck.hasOwnProperty(key) && experianHistoryCheck[key] === true) {
        // Only override if the value in experianHistoryCheck is true
        result[key] = true;
      }
    }

    return result;
  }

  resetForm() {
    this.formTarget.classList.remove('was-validated');
    this.vrmInputTarget.value = '';
    this.mileageInputTarget.value = '';
  }

  retry() {
    this.resetForm();
    this.errorTarget.classList.add('d-none');
    this.formTarget.classList.remove('d-none');
    if (this.hasExistingVehicleLookupTarget) {
      this.existingVehicleLookupTarget.classList.remove('d-none');
    }
    this.retryButtonTarget.classList.add('d-none');
    if (this.hasFormTarget) {
      const formSubmitBtn = this.formTarget.querySelector('button');
      formSubmitBtn.disabled = false;
    }
  }
}
