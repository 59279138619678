import { Controller } from '@hotwired/stimulus';
import Carousel from 'bootstrap/js/dist/carousel';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from 'photoswipe';

export default class extends Controller {
  static targets = [
    'carousel',
    'thumbnailsCarouselTop',
    'thumbnailsCarouselBottom',
    'currentImageNumberDisplay',
  ];

  static values = {
    currentIndex: { type: Number, default: 0 },
  };

  initialize() {
    this.currentIndex = 0;
  }

  connect() {
    if (this.hasThumbnailsCarouselTopTarget) {
      this.topThumbnailsCarousel = new Carousel(this.thumbnailsCarouselTopTarget);
    }
    if (this.hasThumbnailsCarouselBottomTarget) {
      this.bottomThumbnailsCarousel = new Carousel(
        this.thumbnailsCarouselBottomTarget
      );
    }
    this.mainCarousel = new Carousel(this.carouselTarget);
    this.carouselTarget.addEventListener(
      'slide.bs.carousel',
      ({ from, to, direction }) => {
        if (direction === 'left') {
          this.topThumbnailsCarousel?.next();
          this.bottomThumbnailsCarousel?.next();
        } else {
          this.topThumbnailsCarousel?.prev();
          this.bottomThumbnailsCarousel?.prev();
        }
        this.currentIndex = to;
        if (this.hasCurrentImageNumberDisplayTarget) {
          this.currentImageNumberDisplayTarget.innerText = to + 1;
        }

        const carouselSlides =
          this.carouselTarget.querySelectorAll('.carousel-item');

        // this.element.querySelectorAll('.carousel-item img').forEach((image) => {
        //   image.addEventListener('load', (e) => {
        //     e.target.parentNode.classList.remove('is-loading');
        //   });
        // });

        // const slideToPreload =
        //   carouselSlides[(this.currentIndex + 3) % carouselSlides.length];
        // return this.itemArr[(this.currentIndex - 3 + this.itemArr.length) % this.itemArr.length];
        const slideToPreload =
          carouselSlides[
            (this.currentIndex - 3 + carouselSlides.length) % carouselSlides.length
          ];

        slideToPreload?.querySelector('img').setAttribute('loading', '');
      }
    );

    const lightboxInstance = this.initLightbox();
    if (lightboxInstance) {
      lightboxInstance.on('beforeOpen', () => {
        // pause swiper when lightbox shows
        setTimeout(() => {
          // ugly but necessary evil...
          // drawsSlider.autoplay.pause();
        }, 1);
      });

      lightboxInstance.on('change', () => {
        const slideIndex = lightboxInstance.pswp.currIndex;
        setTimeout(() => {
          // drawsSlider.slideTo(slideIndex);
          this.mainCarousel.to(slideIndex);
        }, 300);
      });

      lightboxInstance.on('close', () => {
        // unpause swiper when lightbox hides
        // drawsSlider.autoplay.resume();
      });

      lightboxInstance.on('tapAction', (e) => {
        const target = e?.originalEvent?.target;
        if (target) {
          const shouldClose =
            target.matches('.pswp__item') &&
            !target.matches('.pswp__img') &&
            !target.matches('.pswp__top-bar');

          if (shouldClose) {
            lightboxInstance.pswp.close();
          }
        }
      });

      const thumbnails = document.querySelectorAll(
        '[data-thumbnails-wrapper] .carousel-item'
      );
      [...thumbnails].forEach((thumbnail) => {
        thumbnail.addEventListener('click', (e) => {
          const fullImageIndex = parseInt(e.target.dataset.index, 10);
          lightboxInstance.loadAndOpen(fullImageIndex);
        });
      });
    }
  }

  initLightbox() {
    const el = this.element?.querySelector('[data-slides-wrapper]');

    if (!el) {
      return null;
    }

    const lightbox = new PhotoSwipeLightbox({
      gallery: el,
      children: 'a',
      pswpModule: PhotoSwipe,
      showHideAnimationType: 'fade',
      preload: [1, 3],
      wheelToZoom: true,
      preloaderDelay: 200,
      maxZoomLevel: 1.5,
    });
    lightbox.init();

    return lightbox;
  }
}
