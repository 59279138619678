import Rails from '@rails/ujs';

export class CardsPreview {
  constructor(cardsPreview) {
    this.cardsPreview = cardsPreview;
    this.loader = this.cardsPreview.querySelector(
      '[data-cards-preview-loader]'
    );

    this.contentWrapper = this.cardsPreview.querySelector(
      '[data-cards-preview-content]'
    );
    this.setupEventListeners();
  }

  setupEventListeners() {
    const tabEls = this.cardsPreview.querySelectorAll('[data-bs-toggle="tab"]');
    [...tabEls].forEach((tabEl) => {
      tabEl.addEventListener('show.bs.tab', (e) => {
        const activatedTab = e.target;
        const { make } = activatedTab.dataset;
        this.requestContent(make);
        this.setMakeQueryParam(make);
      });
    });
  }

  requestContent(make) {
    const url = new URL(window.location);
    url.searchParams.delete('make');
    url.searchParams.set('make', make);

    this.loader.classList.remove('d-none');
    this.contentWrapper.innerHTML = '';

    setTimeout(() => {
      Rails.ajax({
        url,
        type: 'get',
        complete: (data) => {},
        success: (data) => {
          this.loader.classList.add('d-none');
        },
        error: (data) => {},
      });
    }, 800);
  }

  setMakeQueryParam(make) {
    const url = new URL(window.location);
    if (make === 'all') {
      url.searchParams.delete('make');
    } else {
      url.searchParams.set('make', make);
    }
    history.pushState(null, '', url);
  }
}
