import { Controller } from '@hotwired/stimulus';
import { useIntersection } from 'stimulus-use';
import { get } from '@rails/request.js';

export default class extends Controller {
  static values = {
    listId: String,
    url: String,
    signedIn: { type: Boolean, default: false },
  };

  static targets = ['loader', 'requestMoreButton'];

  connect() {
    this.page = 1;
    this.fetchingData = false;

    useIntersection(this);

    // Add a class to an element we are about to add to the page
    document.addEventListener('turbo:before-stream-render', function (event) {
      if (event.target.firstElementChild instanceof HTMLTemplateElement) {
        const newElementsFromStreamArr = [...event.target.templateElement.content.children];
        newElementsFromStreamArr.forEach((el) => {
          el.classList.add('fadeInUp');
        });
      }
    });
  }

  async requestMore() {
    if (this.hasRequestMoreButtonTarget) {
      this.requestMoreButtonTarget.classList.add('d-none');
    }

    if (this.fetchingData || this.hasNoMoreResultsItem) return;

    if (this.hasLoaderTarget) {
      this.loaderTarget.classList.remove('d-none');
    }
    this.fetchingData = true;
    this.page += 1;
    // console.log('load-more', this.page, this.listIdValue, this.urlValue);

    await get(this.urlValue, {
      query: {
        page: this.page,
        turbo_target: this.listIdValue,
      },
      responseKind: 'turbo-stream',
    });

    this.fetchingData = false;
    if (this.hasLoaderTarget) {
      this.loaderTarget.classList.add('d-none');
    }
    if (this.hasNoMoreResultsItem && this.hasRequestMoreButtonTarget) {
      this.requestMoreButtonTarget.classList.add('d-none');
    }

    setTimeout(() => {
      if (!this.hasNoMoreResultsItem && this.hasRequestMoreButtonTarget) {
        this.requestMoreButtonTarget.classList.remove('d-none');
      }
    }, 100);
  }

  async appear() {
    if (!this.signedInValue && this.page >= 2) {
      // same as AirBnB - only request 2nd page using infinity scroll when user isn't signed in
      return;
    }
    this.requestMore();
  }

  urlValueChanged() {
    this.page = 1;
  }

  get hasNoMoreResultsItem() {
    return document.getElementById(this.listIdValue).querySelector(`[data-no-more-results]`) != null;
  }
}
